<template>
  <el-main>
    <div class="main">
      <h2 class="title">新增车型</h2>
      <div class="personal-form">
        <el-form status-icon label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名：" prop="nick_name">
            {{ nick_name }}
          </el-form-item>
          <el-form-item label="真实姓名：" prop="pass">
            {{ real_name }}
          </el-form-item>
          <el-form-item label="性别：" prop="pass">
            {{ sex }}
          </el-form-item>
          <el-form-item label="身份证号码：" prop="pass">
            {{ idcard }}
          </el-form-item>
          <el-form-item label="手机号码：" prop="checkPass">
            {{ phone }}
          </el-form-item>
          <el-form-item label="选择车型：" prop="licence">
            <el-cascader
              v-model="licence"
              placeholder="选择车型"
              :options="licencedata"
              :props="defaultParams"
              @change="handleChangelicence"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addsubmit()">
              新增车型
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-main>
</template>
<script>
import { getResult, getLicenceName } from '../utils/auth'
import { addlicence, userlicence } from '../api/user'
import { getlicence } from '../api/licence'

export default {
  components: {},

  data() {
    return {
      avatar: '',
      real_name: '',
      licence_title: '',
      licence_name: '',
      jiaxiao_name: '',
      licence: [],
      licencedata: [],
      defaultParams: {
        label: 'licence_name',
        value: 'licence_id',
        children: 'children'
      },
      licence_id: ''
    }
  },
  created() {
    this.licence_name = getLicenceName()
    this.getInfo()
    this.getLicence()
    this.userLicence()
  },
  methods: {
    //用户信息
    getInfo() {
      this.nick_name = JSON.parse(getResult()).nick_name
      this.idcard = JSON.parse(getResult()).idcard
      this.phone = JSON.parse(getResult()).phone
      this.avatar = JSON.parse(getResult()).avatar
      this.real_name = JSON.parse(getResult()).real_name
      this.licence_title = JSON.parse(getResult()).licence_title
      this.jiaxiao_name = JSON.parse(getResult()).jiaxiao_name
      this.jiaxiao_address = JSON.parse(getResult()).jiaxiao_address
      if (JSON.parse(getResult()).sex === 0) {
        this.sex = '未知'
      } else if (JSON.parse(getResult()).sex === 1) {
        this.sex = '男'
      } else if (JSON.parse(getResult()).sex === 2) {
        this.sex = '女'
      }
    },
    //新增车型
    addsubmit() {
      let params = {
        term: 1,
        licence_id: this.licence_id,
        licence_title: this.licence_id
      }
      addlicence(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '添加成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    //
    handleChangelicence(value) {
      this.licence_id = value[1]
      this.licence_title = value[2]
    },
    //获取车型列表
    getLicence() {
      getlicence().then(res => {
        if (res.code === 0) {
          for (let i = 0; i < res.result.length; i++) {
            if (res.result[i].hasOwnProperty('children') === true) {
              for (let s = 0; s < res.result[i].children.length; s++) {
                if (
                  res.result[i].children[s].hasOwnProperty('sublist') === true
                ) {
                  res.result[i].children[s].children = []
                  for (
                    let t = 0;
                    t < res.result[i].children[s].sublist.length;
                    t++
                  ) {
                    let obj = {}
                    obj.licence_name = res.result[i].children[s].sublist[t]
                    obj.licence_id = res.result[i].children[s].sublist[t]
                    res.result[i].children[s].children.push(obj)
                  }
                }
              }
            }
          }
          this.licencedata = res.result
        }
      })
    },
    //已选车型
    userLicence() {
      userlicence().then(res => {
        if (res.code === 0) {
          this.options = res.result
          // for (let i = 0; i < res.result.length; i++) {
          //   for (let s = 0; s < res.result[i].data.length; s++) {
          //     if (this.licence_ids == res.result[i].data[s].licence_id) {
          //       this.licenceData = res.result[i].data
          //       this.licence_id = res.result[i].data[s].licence_id
          //       setLicenceName(res.result[i].data[s].licence_name)
          //     }
          //   }
          // }
        }
      })
    }
  }
}
</script>
